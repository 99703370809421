.projectsContainer {
  background-color: transparent !important;
  border-radius: 0 !important;
  margin: 1rem 0;
  padding: 0 !important;
}

.formContainer {
  box-sizing: border-box;
  border: #009ee0 solid 1px;
  border-radius: 10px;
  padding: 1rem;
  max-width: 900px;
  width: 100%;
}

.fnContainer {
  max-width: 900px;
  width: 100%;
}

.projectsDiv {
  max-width: 1080px;
  width: 100%;
}

.heartIcon {
  font-size: 4rem;
  color: #f24934;
  position: relative;
  top: 52px;
  left: calc(50% - 2rem);
}

.progressText {
  flex-direction: column;
  color: black !important;
}

.formContainer a,
.formContainer a:hover {
  text-decoration: none;
  color: black;
}

.fn_icons {
  position: relative;
  max-width: 260px;
  width: 100%;
  cursor: pointer;
}

.fn_icons img {
  width: 100%;
}

.bkm_text {
  box-sizing: border-box !important;
  max-width: 600px;
  width: 100%;
  margin: 1rem !important;
}

.homeSlider {
  z-index: 1;
  position: relative;
}

.headerDiv {
  box-sizing: border-box;
}

.headerDiv span {
  position: absolute;
  z-index: 10000;
  bottom: 6%;
  left: 10%;
  display: flex;
  flex-direction: column;
  justify-items: flex-start;
  background-color: #009ee0;
}

.headerDiv span > a {
  cursor: pointer;
  font-size: 1em;
  text-decoration: none;
  color: #fff;
  padding: 0.2em 0.4em;
  height: fit-content;
  background-color: #f24934;
  display: flex;
  align-items: center;
}

.headerDiv span > a:hover {
  background-color: rgb(242, 73, 52, 0.8);
}

.headerDiv h1 {
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  margin: 0;
  cursor: pointer;
  position: relative;
  font-size: 8vh;
  color: #fff;
  padding: 10px 20px;
  background-color: #009ee0;
  font-family: "BHEART-BOLD", sans-serif;
}

.projectBlock {
  margin: 0.5rem;
  padding: 0.5rem;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.projectBlock:hover {
  transform: scale(1.01);
}

.donateFrame {
  position: relative;
  width: 100%;
  max-width: 500px;
  left: 10%;
  z-index: 1;
  margin-top: -7%;
  padding: 0.5em 1em;
  /* background-color: #009ee0 */
  background-color: #fff;
  border: #f24934 2px solid;
}

.donateFrame h5 {
  font-weight: 600;
}

.donateFrame iframe {
  border: none;
  padding: 0;
  margin: 0;

  height: 300px;
  width: 100%;
}

@media only screen and (max-width: 700px) {
  .donateFrame,
  .headerDiv span {
    left: 0;
  }
}
