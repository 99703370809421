.donations { 
    max-width: 900px; width: 100%; }

.confirmForm {
    padding: 1rem;
    max-width: 600px;
    width: 100% !important;
    margin-top: 2rem; }

.dForm {
    position: relative;
    z-index: 0;
    background-color:rgba(39,46,61,1);
    max-width: 1024px; 
    width: 100% !important; 
    padding: 2.5rem 0 !important; }

.donateForm { 
    max-width: 900px; 
    width: 100% !important; }

.featuredImage { 
    max-width: 100%;
    width: 100%;
    height: 300px; 
    object-fit: cover; }
      
.blifadderBackground, .blifastgiverBackground, .stottOssBackground { 
    height: 70vh !important;
    
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover; }

.blifadderBackground { 
    background-image: url('../files/sponsor.png'); }

.blifastgiverBackground {
    background-image: url('../files/regular.png'); }

.stottOssBackground { 
    background-image: url('../files/main.jpg'); }
      