.omOssBackground {
    padding: 0 !important;
    margin: 0 !important;
    background-image: url('../files/1.jpg');
    height: 70vh !important;
  
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover; }

.zambiaBackground {
    padding: 0 !important;
    margin: 0 !important;
    background-image: url('../files/zambia.jpg');
    height: 70vh !important;
  
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover; }

.norwayBackground {
    padding: 0 !important;
    margin: 0 !important;
    background-image: url('../files/norway.jpg');
    height: 70vh !important;
  
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover; }

.styreDiv, .orgaigram, .ab-btns, .ab-history {
    max-width: 900px;
    width: 100%; }

.styrePerson img { 
    object-fit: cover !important; 
    max-height: 300px;
    max-width: 300px; 
    width: 100% !important; 
    height: 100% !important; }

.styrePerson { 
    width: 100%;
    margin: 2rem 0; }

.staff_about { 
    box-sizing: border-box;
    padding: 1rem;
    max-width: 600px; 
    width: 100%; }

.card-header button { 
    font-family: 'BHEART-MEDIUM', sans-serif !important;
    font-size: 20px !important; }

.ab-btns button {
    margin: 5px; 
    width: 300px; 
    border: none;
    border-radius: 6px; 
    min-height: 200px; }

.ab-no, .ab-za { 
    background-color: rgba(39,46,61,1); }

.ab-no h4, .ab-za h4 { 
    color: white; }

.orgaigram img { 
    max-width: 900px;
    width: 100%; 
    object-fit: cover; }