/* THIRD-PARTY LIBRARIES: Antdesign: https://ant.design 
   And React Boostrap: https://react-bootstrap.github.io =====================================   */
@import '~antd/dist/antd.css';
@import '~bootstrap/dist/css/bootstrap.min.css';

/* CUSTOM FONTS ==============================================================================   */
@font-face {
  font-family: 'BHEART-REGULAR';
  src: url('./media/fonts/gr/Gilroy-Regular.woff'); }

@font-face {
  font-family: 'BHEART-MEDIUM';
  src: url('./media/fonts/gr/Gilroy-Medium.woff'); } 

@font-face {
  font-family: 'BHEART-BOLD';
  src: url('./media/fonts/gr/Gilroy-Bold.woff'); }

@font-face {
  font-family: 'BHEART-HEAVY';
  src: url('./media/fonts/gr/Gilroy-Heavy.woff'); }

/*  OVERROLL CSS =============================================================================   */
html {
  height: 100%;
  margin: 0; }
  
body {
  height: 100%;
  font-size: calc(1rem + 4px);
  font-family: 'BHEART-REGULAR', sans-serif; }

h1, h2, h3, h4, h5, h6, button { 
  font-family: 'BHEART-MEDIUM', sans-serif; }

a { 
  height: 100%;
  display: inline-block;
  text-decoration: none !important; }

.App { overflow-x: hidden; }

.webcontainer {
  max-width: 100vw;
  width: 100%;
  display: flex;
  justify-content: center !important;
  box-sizing: border-box !important;
  padding: 8rem 1rem !important; }

.textUnderline { 
  border-bottom: 2px solid white !important; }

.textUnderlineRed {
  border-bottom: 2px solid #F24934 !important; }

.w-100 { 
  width: 100% !important; }

.boldText { 
  font-family: 'BHEART-BOLD', sans-serif; }

.fotoText {
  background-color: #F24934;
  border-bottom-right-radius: 4px;
  width: auto !important;
  color: white;
  padding: 0 10px;
  font-size: 16px; }

.oButtons {
  border: #009EE0 solid 1px;
  border-radius: 2px;
  background-color: #009EE0;
  color: white; }

.oButtons:hover {
  background-color: #F24934;
  border: solid 1px #F24934;
  color: white; }

.router {
  display: flex;
  align-items: center !important;
  color: #F24934;
  margin-bottom: 0.5rem; }

.støttDiv {
  position: relative;
  box-sizing: border-box;
  border-radius: 0.5rem;
  padding: 0 !important;
  margin: 8px !important;
  transition: all .2s ease-in-out;
  text-align: center; }

.støttDiv:hover {
  transform: scale(1.01); }

.stottText {
  position: absolute;
  background-color: #009EE0;
  padding: 0.4rem;
  margin: 0;
  bottom: -8%;  /* Dont know why this is acting weird placement */
  left: 50%;
  width: 100%;
  transform: translate(-50%, -50%); }

.notActive, .MuiTypography-h5 { 
  font-family: 'BHEART-MEDIUM', sans-serif !important; }

#Navbar + .homeSlider { margin-top: 6rem; } /* height of nav is 6rem */
.navdisplace { padding-top: 6rem; }

.pageDiv {
  box-sizing: border-box;
  padding: 1rem;
  max-width: 980px !important; 
  width: 100%; }

.text-red { color: #F24934 !important; }

.dOptionButton {
  height: 4rem;
  margin: 1rem 0 0.5rem 0;
  border-radius: 4px !important; }

/*  MAIN APP COMPONENT =======================================================================   */
.ant-row { margin-bottom: 0.3rem !important; }

/* TODO Update Class Name For Orange Hover. */
.text-brown, .orangeHover:hover {
  color: white; 
  border-bottom: 2px #F24934  solid !important;  }

.text-blue { color: #009EE0 !important; }

.text-black { color: black !important; }

.orangeHover { border-bottom: 2px solid transparent; }

.MuiRadio-root, .MuiCheckbox-root { color: white !important; }

.MuiFilledInput-underline::after, .MuiFilledInput-underline::after { border-bottom: none !important; }

.MuiFilledInput-underline::before, .MuiInput-Underline::before { border: none !important; }

.MuiFormLabel-root.Mui-focused { color: #009EE0!important; }

.MuiFormLabel-root {
  font-size: 16px !important;
  font-family: 'BHEART-REGULAR', sans-serif !important; }

.MuiCardContent-root {
  background-color: #009EE0 !important;
  color: white !important;
  padding: 5px !important; }

.MuiInputBase-root {
  font-family: 'BHEART-REGULAR', sans-serif !important;
  border-radius: 2px !important;
  background-color: white !important; }
 
.MuiFormControlLabel-label, .MuiTypography-h5 { color: white !important; }

.MuiFormHelperText-root {
  color: white !important; 
  font-family: 'BHEART-REGULAR', sans-serif !important;
  margin-bottom: 1rem !important; }

.MuiMenuItem-root, .MuiTypography-colorTextSecondary, .MuiTypography-root { font-family: 'BHEART-REGULAR', sans-serif !important; }

.MuiSelect-icon { color: #009EE0 !important; }

/*  NAVBAR ===================================================================================   */
#search, #menuIcon, .ant-input-suffix, #closeButton { cursor: pointer; }

#closeButton {
  color: white !important;
  font-size: 3rem !important; }

.mobileSrchIcon { font-size: 20px !important;}

.hambugerIcon { font-size: 2.5rem; }

.searchContainer {
  margin: 2rem 0 2rem 0;
  width: 85vw; }

.ant-input-affix-wrapper {
  border-bottom: 2px solid #009EE0 !important;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-radius: 0;
  padding: 0; }

.ant-input { 
  font-size: 1.2rem; 
  padding: 0 !important; } 

.ant-input-affix-wrapper-focused, #donateAmount:focus, .ant-select-focused { 
  outline: none;
  box-shadow: none; }

.ant-tooltip { max-width: 1000px; }

/* Disable arrow on drop down menu */
.ant-tooltip-arrow { display: none !important; }

/* Tootip Content Div */
.ant-tooltip-content {
  display: grid !important;
  place-items: center !important; }

.socialMediaIcons a span {
  font-size: 2rem;
  margin: 0 1rem 0 0;
  color: white; }

.socialMediaIcons { margin: 2rem 0 2rem 0; }


/*  HOMEPAGE =================================================================================   */
.akt_focus {
  font-family: 'BHEART-MEDIUM', sans-serif !important;
  padding: 3px;
  background-color: #009EE0;
  color: white;
  border-radius: 0.5rem;
  display: inline; }

.aks_date { color: #F24934; }

.psnv_Background {
  padding: 0 !important;
  margin: 0 !important;
  background-image: url('./media/images/Personvern.jpg');

  height: 80vh !important;

  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover; }

.aktfm_Background {
  padding: 0 !important;
  margin: 0 !important;
  background-image: url('./media/images/fm2.jpeg');

  height: 80vh !important;

  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover; }

.moreProjects { margin-top: 2rem; }

#donateAmount {
  padding: 0.5rem;
  font-size: inherit; }

.ant-select-selector {
  border-radius: 0.5rem;
  align-items: center !important;
  color: black !important;  }

.ant-radio-button-wrapper { 
  align-items: center !important;
  width: 110px !important; 
  font-size: 1.2rem !important; }

.MuiTypography-body2 { font-weight: normal !important;}

.donateInputHome { width: 100% !important; }

.confirmDonateButtons {
  margin: 0.5rem;
  height: 70px;
  border-radius: 4px; }

.otherWay { margin-right: 0.5rem; }

#ReactBackgroundSlider {
  position: relative !important;
  height: 100vh !important; }


/*  ENGASJER =================================================================================   */

.dBackgroundImage {
  padding: 0 !important;
  margin: 0 !important;

  background-image: url('./media/images/dfadder.jpeg');
  height: 80vh !important;

  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover; }

.voluntørInput {
  margin-bottom: 1rem; 
  width: 100% !important; }

.fileUpload {
  padding: 6px;
  background-color: white;
  border-radius: 2px;
  color: black; }

/*  PROJECTS =================================================================================   */
.singleProjectJumbo {
  border-radius: 0;
  background-color: transparent;
  padding: 0; }

.mainProject, .projectBody {
  max-width: 1000px;
  padding: 0rem 2rem 1rem 2rem;
  margin-bottom: 5rem; }

.carousel-item { max-height: 650px; }

.carousel-item img { 
  height: 100% !important;
  object-fit: contain !important; }

.aboutProject { padding: 2rem; }

/*  STØTT-OSS  ===============================================================================   */
.termsBackground {
  padding: 0 !important;
  margin: 0 !important;
  background-image: url('./media/images/salgsbetingelser.jpg');

  height: 80vh !important;

  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;  }

.terms p {
  margin-bottom: 0.3rem; }

.terms a {
  display: inline;
  font-size: inherit !important; }

.terms h5 {
  display: inline !important;
  font-size: inherit !important;
  margin: 0 !important; }

.otherMethods {
  padding: 1rem; 
  margin-top: 3rem; }

.dButtons { 
  color: white;
  border: 1px solid white; }

.dButtons:hover { 
  background-color: #009EE0;
  border: 1px solid #009EE0; }

/* Donate Options Images Here */
.cards { font-size: 2rem !important; }

.sDiv { display: grid; }

.cardFormDiv {
  height: 55px;
  background-color: white;
  display: grid;
  align-items: center;
  padding: 0.5rem;
  border-radius: 2px; }

.successfullPaymentDiv {
  text-align: center !important;
  margin-top: 1rem;
  padding: 0.5rem;
  background-color: rgb(39, 46, 61.1);
  width: 90%;
  border-radius: 0.5rem;
  display: grid;
  place-items: center; }

.ant-result-title { color: black !important; }

.payment-success-div {
  margin: 0.5rem;
  padding: 0.5rem; }

.sp-link {
  margin: 0 5px 0 0; }

/*  FOOTER   =================================================================================   */
.ant-result {
  padding: 4px !important; }

/*  MEDIA QUERIES ============================================================================   */
/* For Screens From Width 320px. */
@media screen and (min-width: 320px) {
  .ant-input-affix-wrapper { font-size: 1.8rem; }
  .DesktopNav, .mobileStøtt { display: none; }
  .sForm { width: 80%; }
  .donatePageMain { width: 96%; } }

/* For Screens From Width 481px. */
@media screen and (min-width: 481px) {
  .mobileStøtt { display: block; } }

/* For Screens From Width 769px. */
@media screen and (min-width: 769px) {
  .ant-input-affix-wrapper { font-size: 3rem; } }

/* For Screens From Width 1025px. */
@media screen and (min-width: 1025px) {
  .sForm { width: 50%; }
  .mRow { display: none; }
  .DesktopNav { display: block; }
  .donatePageMain { width: 900px; } }