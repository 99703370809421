.bedriftsBackground {
    height: 70vh !important;

    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover; }

.bedriftsBackground {
    background-image: url('../files/camp.jpeg'); }