.barnehjemBackground,
.helseBackground,
.utdanningBackground,
.projectsBackground,
.jobbBackground,
.misjonBackground,
.safehomeBackground,
.purehomeBackground,
.jenteinternatBackground,
.road44Background,
.peaceBackground,
.idrettBackground,
.samarbeidBackground {
  height: 70vh !important;

  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
}

.barnehjemBackground {
  background-image: url("../files/barnehjem.jpg");
}

.helseBackground {
  background-image: url("../files/helse.png");
}

.utdanningBackground {
  background-image: url("../files/utdanning.png");
}

.jobbBackground {
  background-image: url("../files//jobs.png");
}

.misjonBackground {
  background-image: url("../files/misjon.png");
}

.safehomeBackground {
  background-image: url("../files/safehome.png");
}

.projectsBackground {
  background-image: url("../files/main.png");
}

.purehomeBackground {
  background-image: url("../files/purehome.jpg");
}

.jenteinternatBackground {
  background-image: url("../files/main.png");
}

.road44Background {
  background-image: url("../files/road44.jpg");
}

.peaceBackground {
  background-image: url("../files/safehome.png");
}

.idrettBackground {
  background-image: url("../files/idrett.png");
}

.samarbeidBackground {
  background-image: url("../files/samarbeid.jpg");
}
