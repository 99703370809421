.wifs { 
    max-width: 900px; 
    max-height: 500px;
    width: 100%; 
    height: 100%; 
    object-fit: cover; 
    border-radius: 4px; }

.bliVoluntør { 
    border-radius: 4px;
    padding: 2rem 0 2rem 0; 
    width: 100% !important;
    background-color: rgba(39,46,61,1); }

.engasjerDegBackground, .blifrivilligBackground {
    height: 70vh !important;
  
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover; }

.engasjerDegBackground { 
    background-image: url('../files/11.jpeg'); }

.blifrivilligBackground { 
    background-image: url('../files/12.png'); }