.footerContainer {
    border-radius: 0 !important;
    margin: 0 !important;
    background-color: rgba(39,46,61,1) !important;
    color: white; }

.fa-c, .ff-copy, .FTB { 
    max-width: 1080px; 
    width: 100%; }
    
.footerBlock { 
    max-width: 230px; 
    width: 100%; }

.footerHeader {
    cursor: default; 
    color: white; }

.footerBlock p a { 
    color: white !important; 
    text-decoration: none; }

.footerBlock p { 
    margin-bottom: 1px; 
    color: white;
    border-bottom: 2px solid transparent; }

.footerBlock { 
    margin: 1rem; }

#footerSocialMedia {
    padding: 1rem 0 1rem 0; }
  
#footerSocialMedia a { 
    display: inline !important; }
  
#footerSocialMedia a span {
    margin: 0 1rem 0 0;
    font-size: 26px;
    color: white; }
  
#footerSocialMedia a span:hover { 
    color: #F24934; }

.footerLogos img { 
    margin-top: 2rem; 
    margin-right: 0.5rem; }

.ft_about p {
    margin: 0 !important; }

.ft_about_bd , .footerNewsletter { 
    box-sizing: border-box;
    max-width: 460px;
    width: 100%;
    margin: 1rem; }

.footerNewsletter { margin-top: 1.5rem; } /* lining up the footer. */

.footerCopyright {
    font-size: 14px !important; }
    