.startInnsamlingBackground, .nødshjelpBackground {
    padding: 0 !important;
    margin: 0 !important;
    height: 70vh !important;
  
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover; }

.startInnsamlingBackground { 
    background-image: url('../files/1.png'); }

.nødshjelpBackground { 
    background-image: url('../files/2.png'); }