.DesktopNav,
.mRow {
  box-sizing: border-box !important;
  padding: 1rem;
  max-width: 1024px !important;
  width: 100% !important;
}

/* links in navbar */
.DesktopNavLinks a,
.language {
  height: 29px; /* this is the height of the nav. cna be changed when needed just in case */
  font-size: 18px !important;
  cursor: pointer;
  font-family: "BHEART-MEDIUM", sans-serif !important;
}

.DesktopNavLinks a,
#search,
#dropDownLink,
#menuIcon,
.language {
  margin: 0 0.5rem 0 0.5rem !important;
}

/* mega link items */
.megaLinkItem {
  border-radius: 0.2rem;
  max-width: 260px !important;
  width: 100% !important;
  padding: 0.5rem;
}

.megaLinks {
  box-sizing: border-box !important;
  padding: 0 !important;
  margin: 0 !important;
  max-width: 270px !important;
  width: 100% !important;
  transition: all 0.2s ease-in-out;
  display: grid;
  place-items: center !important;
  height: 72px !important;
} /* height of dropdown can be changed */

.megaLinks:hover {
  border-radius: 6px;
  background-color: #aed7f1;
}

/* .megaLinkItem h6 {
    font-family: 'BHEART-BOLD', sans-serif !important; } */

.megaLinkItem p {
  width: 100% !important;
  margin: 0 !important;
  color: #5d6d7e;
}

.megaLinkItem .col {
  padding: 0 0px 0 8px !important;
}

.megaLinkItem .col h6 {
  margin-bottom: 0px !important;
}

/* icons in mega menus */
.givedonate,
.vpartner,
.iconSettings {
  font-size: 1.5rem;
  color: #009ee0 !important;
}

/* drop down content */
#engasjerDegDropdown,
#vårtArbeidDropDown,
#stottOssDropDown,
#bedriftsSamarbeidDropdown {
  display: grid !important;
  align-items: center !important;
  justify-content: center !important;
  padding: 0 !important;
  margin: 0 !important;
  border-radius: 0.4rem;
  max-width: 640px;
  width: 100%;
}

.drop-content {
  display: grid;
  place-items: center !important;
  margin: 0.8rem 0 0.8rem 0 !important;
}

.ant-tooltip-placement-bottom {
  top: 88px !important;
}

#Navbar {
  position: fixed;
  z-index: 2;
  background-color: #009ee0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 6rem !important; /* setting fixed height reduces glitch on stickyness */
  width: 100% !important;
  transition: top 0.3s;
}

/* mRow */
.mobileNavContainer {
  width: 100%;
}

.closeButtonRow {
  width: 80%;
  margin: 1rem 0;
}

.mobileContent {
  display: grid;
}

/* sticky function  NOT NEEDED ANYMORE */
.sticky {
  top: 0;
  position: fixed;
  width: 100%;
  z-index: 1;
  background-color: #009ee0 !important;

  /* Shadow Below The Navbar
    -moz-box-shadow: 0 1px 3px gray;
    -webkit-box-shadow: 0 1px 3px gray;
    box-shadow: 0 1px 3px gray;

    /* Background Color Fades In Slowly. */
  -webkit-transition: background-color 500ms ease-in;
  -ms-transition: background-color 500ms ease-in;
  transition: background-color 500ms ease-in;
}
