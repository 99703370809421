.bkt_Background, .povertyBackground {
    height: 70vh !important;

    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover; }

.bkt_Background { 
    background-image: url('../files/goals.png'); }

.povertyBackground { 
    background-image: url('../files/poverty.png'); }

.bkt_img {
    cursor: pointer; }
      